export const environment = {
  production: false,
  stripe_token: "STRIPE_TOKEN",
  paypal_token: "PAYPAL_TOKEN",
  loadGif: "assets/images/loader.gif",
  currency: "",
  default_language: "en",
  
  // PARAMETROS ANE
  firebase: {
      apiKey: "AIzaSyC0wsK12rzLtf_LRGUUw02YWnJ5ZFZ9a4k",
      authDomain: "ianos-app-gt-ane-leather-goods.firebaseapp.com",
      projectId: "ianos-app-gt-ane-leather-goods",
      storageBucket: "ianos-app-gt-ane-leather-goods.appspot.com",
      messagingSenderId: "134243181561",
      appId: "1:134243181561:web:ee37d8589499da37cd5328",
      measurementId: "G-BE2L1TJLSQ"
  },
  googleMapsKey: 'AIzaSyCnyj164hLm7drlr85gbQ8tAIQD_ci8KxE',
  urlBase: 'https://us-central1-ianos-app-gt-ane-leather-goods.cloudfunctions.net',
  apikey: 'c63f5835a7c-799e7e5aa9fc3-425f-09863624',
  urlBaseWompy: 'https://us-central1-ianos-app-gt-ane-leather-goods.cloudfunctions.net/Wompi',
  
  // TWILIO CREDENCIALES IanOS PRO
  twilio: {
    TWILIO_SERVICE_ENDPOINT:
      "https://verify.twilio.com/v2/Services/VA037cd4a1d0556aceea8d4d9524af6c24/Verifications",
    TWILIO_SERVICE_VERIFICATION_CHECK: `https://verify.twilio.com/v2/Services/VA037cd4a1d0556aceea8d4d9524af6c24/VerificationCheck`,
    TWILIO_AUTH_TOKEN: "eae5d7503587447a21af4311385d376c",
    TWILIO_ACCOUNT_SID: "ACe4c513b1b576a81a7304c06951362f8c",
    TWILIO_SERVICE_TOKEN: "VA037cd4a1d0556aceea8d4d9524af6c24",
    TWILIO_MESSAGING_SERVICE_SID: "MG2d0333b6effc81c461f2f6a837b28aa0",
    // TWILIO_MESSAGING_SERVICE_SID: 'MGdb0d97a058ccfdc75431f7c1aa3a553d'
  },

  // CREDENCIALES DE PRO ITWC
  wompi: {
    grant_type: "client_credentials", // Dejar siempre client_credentials.
    audience: "wompi_api", // Dejar siempre "wompi_api"
    client_id: "dc4c16b2-08d9-4478-9037-40a580004f6b", // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
    client_secret: "2c1c79a5-8315-4989-8ade-1469183c0810", //Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
  },

  // // CREDENCIALES DE PRO New Rolling
  // wompi: {
  //   grant_type: 'client_credentials', // Dejar siempre client_credentials.
  //   audience: 'wompi_api', // Dejar siempre "wompi_api"
  //   client_id: 'aa4c30e1-0757-4d03-a20f-b9cc2663bf5c', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
  //   client_secret: '259c97c1-06fe-4137-ab29-b331b92f0fcb', // Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
  // },

  mailchimp: {
    apiKey: "4d1b5faa7e6c99414d58feb265309a36-us22",
    server: "us22",
    listUniqueId: "d5e6af4f94",
  },
};
